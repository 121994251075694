export const colors = {
    Primary1: "#ffffff",
    // Primary2: "#ce0a76",
    Primary2: "#db4ba3",
    Primary3:"#ce0a7690",
    green: '#5BB264',
    darkGrey: '#393937',
    blue: '#085DAD',
    grey: '#f5f5f5',
    lightGreen: '#EEF7E1',
    lightPink: '#f7e1e1',
    iconColor: '#E53988',
    white: '#FBFBFF',
    black: '#000000',
    logoPink: '#bc3061',
  };